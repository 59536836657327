import Cookies from "js-cookie"

class Config {
    api = "";
    dashboard = "";
    environment = process.env.REACT_APP_ENV;
    version = process.env.REACT_APP_VERSION;

    constructor() {
        switch(process.env.REACT_APP_ENV){
            case "develop":
                if (process.env.REACT_APP_DEV_TOKEN){
                    Cookies.set("token", process.env.REACT_APP_DEV_TOKEN, { domain: "localhost" })
                }
                this.api = process.env.REACT_APP_API_DEV;
                this.dashboard = process.env.REACT_APP_DASHBOARD_DEV;
                this.segment = process.env.REACT_APP_SEGMENT_DEV;
                break;
            case "stage":
                this.api = process.env.REACT_APP_API_STAGE;
                this.dashboard = process.env.REACT_APP_DASHBOARD_STAGE;
                this.segment = process.env.REACT_APP_SEGMENT_PROD;
                break;
            case "production":
                this.api = process.env.REACT_APP_API_PROD;
                this.dashboard = process.env.REACT_APP_DASHBOARD_PROD;
                this.segment = process.env.REACT_APP_SEGMENT_PROD;
                break;
            default:
                throw new Error("Invalid REACT_APP_ENV");
        }

    }
}

//export new frozen singleton instance - first import will initialise, imports after will refer to same instance
export default Object.freeze(new Config());