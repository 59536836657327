import React, { Component } from 'react';
import Config from "../../Config";
import './styles/mobile.scss';

class Mobile extends Component {
    render() {
        return (
            <div className="mobile">
                <div>
                    <img src={require('images/icons/mobile.svg')} alt="Mobile Device" />
                    <p>Speedweaver is not yet optimised for mobile devices, please use a device with a larger screen.</p>
                    <a href={`${Config.dashboard}/logs`} className="btn">Go Back</a>
                </div>
            </div>
        );
    }
}

export default Mobile;
