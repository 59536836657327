import React, { Component } from 'react';
import { Context } from 'context/context';

import ToggleButton from 'react-toggle-button';

import styles from './axisDetails.module.scss';

class AxisDetails extends Component {
    static contextType = Context;

    render() {
        return (
            <>
                <div>
                    <div className={`${styles.titleAndToggle} bgSecondary`}>
                        <p className={styles.headerTitle} >{this.props.headerDetails.label}</p>

                        <div className={styles.chartToggle} data-hint={this.props.hints.chartTypeToggle}>
                            <p>Chart type:</p>
                            <ToggleButton
                                activeLabel={'Bar'}
                                activeLabelStyle={{ width:'55px' }}
                                containerStyle={{display:'inline-block',width:'65px'}}
                                inactiveLabel={'Gauge'}
                                inactiveLabelStyle={{ width:'65px' }}
                                thumbAnimateRange={[1, 45]}
                                trackStyle={{width:'63px'}}
                                onToggle={(value) => {
                                    this.props.changeAxisType(!value, this.props.index)
                                }}
                                value={ this.props.axisType }
                                colors={{
                                    inactive: {
                                        base: this.context.lightTheme ? 'rgb(116, 116, 116)' : 'rgb(65, 66, 68)',
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.splitItems}>
                    <div className={styles.colorSelection} data-hint={this.props.hints.color}>
                        <div>
                            <p>Colour: </p>
                            <span onClick={this.props.toggleOpenColors} className={`${styles.clickForSpans} highlightPrimary`} title={this.props.colors[this.props.index]} style={{backgroundColor: `${this.props.colors[this.props.index]}`}}/>
                            <div className={`bgPrimary ${styles.colorSpans} ${this.props.openColors && this.props.clickedIndex === this.props.index ? styles.colorsOpen : undefined}`}>
                                <div className={styles.colorSpansContainer}>
                                    {this.props.colorsSelection.map((singleColor) => {
                                        let spanStyle = {
                                            backgroundColor: `${singleColor}`,
                                            border: this.props.colors[this.props.index] === singleColor ? '1px solid #ccc' : `1px solid ${singleColor}`
                                        };

                                        return <span onClick={this.props.updateHeaderColor.bind(this, singleColor, this.props.index)} title={singleColor} style={spanStyle}/>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.toggle} data-hint={this.props.hints.yAxisToggle}>
                        <p>Y Axis:</p>
                        <ToggleButton
                            activeLabel={'On'}
                            activeLabelStyle={{ width:'55px' }}
                            containerStyle={{display:'inline-block',width:'65px'}}
                            inactiveLabel={'Off'}
                            inactiveLabelStyle={{ width:'65px' }}
                            thumbAnimateRange={[1, 45]}
                            trackStyle={{width:'63px'}}
                            onToggle={(value) => {
                                this.props.updateYAxis(!value, this.props.index)
                            }}
                            value={ this.props.yAxisType }
                            colors={{
                                inactive: {
                                    base: this.context.lightTheme ? 'rgb(116, 116, 116)' : 'rgb(65, 66, 68)',
                                }
                            }}
                        />
                    </div>
                </div>

                <div className={styles.toggleContainer} data-hint={this.props.hints.channelToggle}>
                    {this.props.headerDetails.channelToggles.map((channelValue, channelIdx) => {
                        let borderStyleObject = {
                            borderBottom: `1px solid ${this.props.colors[this.props.index]}`,
                            borderLeft: `1px solid ${this.props.colors[this.props.index]}`,
                            borderRight: channelIdx === 3 ? `1px solid ${this.props.colors[this.props.index]}` : 'none',
                            borderTop: `1px solid ${this.props.colors[this.props.index]}`,
                        };

                        return (
                            <button
                                className={`bgPrimary ${styles.button} ${channelValue ? styles.toggleOn : styles.toggleOff}`}
                                key={channelIdx}
                                onClick={this.props.toggleChannel.bind(this, this.props.index, channelIdx, !channelValue)}
                                style={borderStyleObject}
                            >
                                C{channelIdx+1}
                            </button>
                        )
                    })}
                </div>

                <div className={styles.minMaxValues}>
                    <div data-hint={this.props.hints.min}>
                        <p>Min:</p>
                        <input onChange={this.props.updateMin.bind(this, this.props.index)} type="number" value={this.props.headerDetails.minValue} />
                    </div>

                    <div data-hint={this.props.hints.max}>
                        <p>Max:</p>
                        <input onChange={this.props.updateMax.bind(this, this.props.index)} type="number" value={this.props.headerDetails.maxValue} />
                    </div>
                </div>
            </>
        )
    }
}

export default AxisDetails;
