import React, {Component} from "react";
import { Context } from 'context/context';
import styles from "./Hints.module.scss";

export default class Hints extends Component {
    static contextType = Context;

    state = {
        hint: ""
    };

    componentDidMount() {
        window.addEventListener("mouseover", (event) => {
            const path = event.path || (event.composedPath && event.composedPath()); //get path event has travelled

            const hintElem = path.find(element => element.dataset?.hint); //find the deepest nested element in path with a hint

            this.setState({
                hint: hintElem?.dataset?.hint || ""
            })
        })
    }

    render() {
        return (
            // <div className={styles.info} data-hint={this.context.hints.hintBar}>
            <div className={styles.info}>
                <img src={require(`images/icons/${this.context.lightTheme ? "info-grey" : "info"}.svg`)}/>
                <span>{this.state.hint}</span>
            </div>
        )
    }
}