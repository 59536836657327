import React, { Component } from 'react';

import Graph from "./graph/Graph";

import styles from './channels.module.scss';

class Channels extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.mapWidth !== prevProps.mapWidth) {
            // If map is toggled, reflow chart to fit in container
            this.props.channelRef.forEach((singleChannel) => {
                if(singleChannel.current !== null) {
                    singleChannel.current.chart.reflow()
                }
            })
        }
    }

    render() {
        let styleObject = {
            width: 'calc(100% - ' + this.props.mapWidth + 'px)',
        };

        let count = 0;

        let arrayOfGaugeToggles = this.props.channelRef.map((singleChannel, channelIdx) => this.props.headers.map(headerData => headerData.channelToggles[channelIdx]));

        let condensedBools = arrayOfGaugeToggles.map(singleArray => singleArray.some(Boolean));

        return (
            <div className={styles.channelContainer} style={this.props.toggleMap ? styleObject : {}} onMouseMove={this.props._onMouseMove} onTouchMove={this.props._onMouseMove} onTouchStart={this.props._onMouseMove} data-hint={this.props.hints.channels}>
                {this.props.channelRef.map((singleChannel, channelIdx) => {
                    let gaugeToggles = this.props.headers.map(headerData => headerData.channelToggles[channelIdx]);

                    if(gaugeToggles.some(Boolean)) {
                        return (
                            <Graph
                                addPlotLine={this.props.addPlotLine}
                                bgPrimary={this.props.bgPrimary}
                                bgSecondary={this.props.bgSecondary}
                                bgTertiary={this.props.bgTertiary}
                                channelToggles={gaugeToggles}
                                chartHeight={this.props.chartHeight}
                                clickedIndex={this.props.clickedIndex}
                                colors={this.props.colors}
                                count={count++}
                                dragOrZoom={this.props.dragOrZoom}
                                endTime={this.props.endTime}
                                graphRef={this.props.channelRef[channelIdx]}
                                headers={this.props.headers}
                                logMapData={this.props.logMapData}
                                newMax={this.props.newMax}
                                newMin={this.props.newMin}
                                index={channelIdx}
                                isMapData={this.props.logMapData.length}
                                isMapEnabled={this.props.toggleMap}
                                key={channelIdx}
                                last={condensedBools.lastIndexOf(true) === channelIdx}
                                logParamData={this.props.logParamData}
                                logTimes={this.props.logTimes}
                                setMapMarker={this.props.setMapMarker}
                                setMinMax={this.props.setMinMax}
                                setTooltipValues={this.props.setTooltipValues}
                                startTime={this.props.startTime}
                                tab={this.props.tab}
                                unitArray={this.props.unitArray}
                                yAxisBools={this.props.yAxisBools}
                            />
                        )
                    }
                })}
            </div>
        )
    }
}

export default Channels;
