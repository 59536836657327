import { request } from './api'
import Cookies from "js-cookie"

export const getLogs = (id) => {
    return new Promise((resolve, reject) => {

        const headers = Cookies.get("token") ? {
            'Authorization': "Bearer " + Cookies.get("token")
        } : {};

        request.get("/ecu_data_logs/" + id, {
            headers
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};

export const downloadLog = (logId) => {
    return new Promise((resolve, reject) => {
        const headers = Cookies.get("token") ? {
            'Authorization': "Bearer " + Cookies.get("token")
        } : {};

        request.get(`/ecu_data_logs/${logId}/export-csv`, {
            headers: {
                'content-type': 'application/ld+json',
                'Authorization': "Bearer " + Cookies.get("token")
            },
            responseType: 'blob'
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};