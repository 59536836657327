import React, { Component } from 'react';
import { Context } from 'context/context';
import Highcharts from 'highcharts/highstock'
import Config from 'Config'
import Cookies from 'js-cookie'

import Axes from "./axes/Axes";
import ButtonToolbar from "./buttonToolbar/ButtonToolbar";
import Channels from "./channels/Channels";
import HeadButtons from "./headButtons/HeadButtons";
import MapViewer from "./mapViewer/MapViewer";
import Mobile from './mobile/Mobile.js'
import Navigator from "./navigator/Navigator";
import UtilityBar from './utilitybar/UtilityBar'
import hints from 'text/hints';

import FullScreen from "react-full-screen";
import { Rnd } from 'react-rnd';

import styles from './viewer.module.scss';
import segment from "../segment";

class Viewer extends Component {
    static contextType = Context;

    state = {
        debugMessages: "",
        errored: "",
        headers: [],
        logCreated: "",
        logId: "",
        logName: "",
        logMapData: [],
        logParamData: [],
        logProject: "",

        axisType: [],
        dragOrZoom: false,
        yAxisBools: [],
        chartHeight: 1,
        clickedIndex: null,
        tab: 1,
        tooltipValues: [],

        colors: [],
        colorsSelection: [
            '#1C5D99', '#870058', '#B3001B', '#CC3F0C', '#CBA328', '#0A6E4F', '#7F7F7F',
            '#28AFB0', '#EA638C', '#DB324D', '#FF6B35', '#EAD637', '#00A676', '#B4B4B4',
            '#87F6FF', '#E88EED', '#FF5A5F', '#FE6500', '#DCF763', '#7DDF64', '#DDDDDD',
        ],
        openColors: false,

        endTime: null,
        logTimes: [],
        startTime: null,

        mapWidth: 400,
        toggleMap: false,
        updateMap: false,

        isFull: false,

        hints,
    };

    // Refs that contain each available Axis, up to 9
    gaugeRefs = [React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()];
    // A ref for each potential channel up to 4
    channelRef = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
    // The single nav bar ref
    navRef = React.createRef();
    // Ref to be used for the mapViewer component
    mapRef = null;
    //Marker ref used in mapViewer marker functions
    marker = null;
    // Global variable used for setMinMax()
    beingUpdated = false;

    // Global background variables for inline styling components
    bgPrimary;
    bgSecondary;
    bgTertiary;


    async componentDidMount() {
        // Quick check to see if user is logged in, otherwise redirect
        const url = new URL(window.location.href);
        let id = url.pathname.replace('/', '');
        if (id === "" || id === undefined) {
            window.location.replace(Config.dashboard)
        }

        // Gets the log from api using the id and sets the state
        const res = await this.context.getLogs(id);
        this.setState({
            ...this.processResData(res),
        });

        //Stops pointer from disappearing automatically
        Highcharts.Pointer.prototype.reset = function () {
            return undefined;
        };

        // Redraws crosshair so that it remembers where it is on the graph and keep sit drawn
        Highcharts.Point.prototype.highlight = function (event) {
            this.onMouseOver(); // Show the hover marker
            this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
        };

        this.bgPrimary = this.context.lightTheme ? '#ededed' : '#1e1e1e';
        this.bgSecondary = this.context.lightTheme ? '#F7F7F7' : '#232323';
        this.bgTertiary = this.context.lightTheme ? '#FFF' : '#292929';
    }

    processResData = (res) => {
        let allParamMapData = [];
        res.data = JSON.parse(res.data);

        // Creates array to contain the arrays on param data
        let allParamData = res.headers.map(() => []);

        // Creates array of geodata from api
        let hideMap = res.data.map((item) => item.geoInfo);
        // Creates a dupe of geoInfo to use later, as hideMap will become array of bools
        let geoData = hideMap;
        // Makes hideMap an array of bools
        hideMap = hideMap.map((item) => !!item);
        // Condenses array into single bool
        hideMap = hideMap.includes(false);

        // If hidemap is false, sets the mapData to be used in the map component
        if (!hideMap) {
            allParamMapData = geoData.map((geoData) => {
                return {
                    lat: geoData.latitude, lng: geoData.longitude
                }
            });
        }

        // Array that contains first value of true, the rest false for each header
        let navigatorSeriesToggles = res.headers.map((header, headerIdx) => headerIdx === 0);

        // Creates array containing bools of if the app gaugetype is bar or not
        let axisType = res.headers.map(headerData => headerData.guageType === "bar");

        let yAxisBools = res.headers.map(() => false);

        // Logtimes is filled with the time values from each data point from paramData
        let logTimes = res.data.map(resData => resData.time);

        let unitArray = res.headers.map((headerData, headerIdx) => {
            return {
                unit: res.headers[headerIdx].unit,
                customUnit: res.headers[headerIdx].userPreferredUnit
            }
        });

        // allParamData contains multiple arrays that contain the param data of each header
        allParamData = res.headers.map((headerData, headerIdx) => {
            let customUnit = headerData.userPreferredUnit;
            let unit = headerData.unit;

            // Perform conversions if pref unit is set
            return res.data.map((resData) => {
                if (unit !== customUnit) {
                    //Convert Celcius to Fahrenheit
                    if (customUnit === '°F') {
                        resData.paramData[`param${headerIdx}`] = (resData.paramData[`param${headerIdx}`] * 9 / 5) + 32
                    }
                    //Convert km/h to mph
                    if (customUnit === 'mph') {
                        resData.paramData[`param${headerIdx}`] = resData.paramData[`param${headerIdx}`] / 1.609
                    }
                    //Convert kPa to Bar
                    if (customUnit === 'Bar') {
                        resData.paramData[`param${headerIdx}`] = resData.paramData[`param${headerIdx}`] / 100
                    }
                    //Convert kPa to psi
                    if (customUnit === 'psi') {
                        resData.paramData[`param${headerIdx}`] = resData.paramData[`param${headerIdx}`] / 6.895
                    }
                }

                return resData.paramData[`param${headerIdx}`]
            });
        });

        res.headers.forEach((headerData) => {
            // headerData.visible = true;
            headerData.channelToggles = [true, false, false, false];
            if (typeof headerData.minValue === 'string') {
                headerData.minValue = JSON.parse(headerData.minValue)
            }

            if (typeof headerData.maxValue === 'string') {
                headerData.maxValue = JSON.parse(headerData.maxValue)
            }
        });

        let originalHeadersMinMaxValues = res.headers.map((headerData) => {
            return {
                minValue: headerData.minValue,
                maxValue: headerData.maxValue
            }
        });

        let colors = res.headers.map((headerData, headerIdx) => {
            if(!headerData.color) return this.state.colorsSelection[headerIdx];

            return headerData.color
        });

        return {
            // Log meta data
            debugMessages: res.debugMessages,
            errored: res.errored,
            headers: res.headers,
            logCreated: res.logCreated,
            logId: res.id,
            logName: res.name,
            logMapData: allParamMapData,
            logParamData: allParamData,
            logProject: res.project,
            unitArray,

            // Navigator min max values
            newMin: logTimes[0],
            newMax: (logTimes[logTimes.length - 1] - logTimes[0]) > 30000 ? logTimes[0] + 30000 : logTimes[logTimes.length - 1],
            originalHeadersMinMaxValues,

            // Colours
            colors,

            // Log times
            endTime: logTimes[logTimes.length - 1],
            logTimes: logTimes,
            startTime: logTimes[0],

            // Axis type either bar or gauge
            axisType,
            // Array of bools to show yAxis on graph
            yAxisBools,

            // Navigator values and selectLabel is the default header shown in dropdown
            navigatorSeriesToggles,
            selectLabel: res.headers[0].label,
        }
    };

    /**
     * Sends tooltip values from graph to axes and updates their display values. Also has function to change the colour of the axis title
     * @param {array} tooltipValues - the array of values from the graph containing the Y values for each series
     */
    setTooltipValues = (tooltipValues) => {
        tooltipValues.forEach((tooltipValue, valueIdx) => {
            if(this.gaugeRefs[valueIdx].current !== null){
                this.gaugeRefs[valueIdx].current.chart.series[0].points[0].update(parseFloat(tooltipValue.toFixed(2)));

                let originalMax = this.state.originalHeadersMinMaxValues[valueIdx].maxValue;

                // Update function changes the colour of the axes title depending on the % of the value in tooltip
                this.gaugeRefs[valueIdx].current.chart.yAxis[0].update({
                    title: {
                        style: {
                            color: (tooltipValue > originalMax * 0.75) ?
                                (tooltipValue > originalMax * 0.90) ?
                                    '#FF5A5F'
                                :
                                    '#EAD637'
                            :
                                this.context.lightTheme ? '#333' : '#fff'
                        }
                    },
                });
            }
        });
    };

    /**
     * This function creates a new google map position and creates a new google map marker, using the map reference and the new google map posiiton
     * If map marker is already set, this function updates the marker with new google map position, instead of creating it
     * @param {object} e - the object containing the lat and lng values from the google map
     */
    setMapMarker = (e) => {
        if(!this.mapRef) return;
        const position = new window.google.maps.LatLng({lat: e.target.geoInfo.lat, lng: e.target.geoInfo.lng});

        if(!this.marker) {
            this.marker = new window.google.maps.Marker({
                position: position,
                map: this.mapRef.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
            });
        } else {
            this.marker.setPosition(position);
        }
    };

    /**
     * Toggle function to change the last clicked axis id
     * @param {number} gaugeId - the gaugeID of the clicked guage.
     */
    seriesToggle = (gaugeId) => {
        this.setState({
            clickedIndex: gaugeId
        });
    };

    /**
     * Sets the extremes to the graph and navigator so they are in sync.
     * @param {object} chartRef - the chartRef inside the channelRef
     * @param {number} newMin - the new min extreme used to set the min value of the xAxis
     * @param {number} newMax - the new max extreme used to set the max value of the xAxis
     */
    setMinMax = (chartRef, newMin, newMax, scroll) => {
        if(this.beingUpdated) {
            return
        }

        this.beingUpdated = true;

        if(chartRef === this.navRef) {
            // Loops through each channel and runs setExtremes function that changes the current min and max values of the chart
            this.channelRef.forEach((graphRef) => {
                if(graphRef.current !== null) {
                    graphRef.current.chart.xAxis[0].setExtremes(newMin, newMax, true, false);
                }
            });
        } else {
            if(this.navRef.current !== null) {
                // Runs setExtremes function that changes the current min and max values of the navigator
                this.navRef.current.chart.xAxis[0].setExtremes(newMin, newMax, true, false);
                this.channelRef.forEach((graphRef) => {
                    // Loops through each channel and runs setExtremes function that changes the current min and max values of the chart
                    if(scroll) {
                        if((graphRef.current !== null)) {
                            graphRef.current.chart.xAxis[0].setExtremes(newMin, newMax, true, false);
                        }
                    } else {
                        if((graphRef !== chartRef) && (graphRef.current !== null)) {
                            graphRef.current.chart.xAxis[0].setExtremes(newMin, newMax, true, false);
                        }
                    }
                });
            }
        }

        this.beingUpdated = false;
    };

    // Sets the extremes to the graph and navigator so they are in sync.
    setFullMinMax = () => {
        if(this.beingUpdated) {
            return
        }
        this.beingUpdated = true;

        this.channelRef.forEach((graphRef) => {
            if(graphRef.current !== null) {
                // SetExtremes function that sets the view min and max to be the entire log
                graphRef.current.chart.xAxis[0].setExtremes(this.state.startTime, this.state.endTime, true, false);
            }
        });

        if(this.navRef.current !== null) {
            // SetExtremes function that sets the view min and max to be the entire log for the navigator
            this.navRef.current.chart.xAxis[0].setExtremes(this.state.startTime, this.state.endTime, true, false);
        }

        this.beingUpdated = false;
    };

    // Sets the extremes to the graph and navigator so they are in sync.
    resetMinMax = () => {
        if(this.beingUpdated) {
            return
        }
        this.beingUpdated = true;

        this.channelRef.forEach((graphRef) => {
            if(graphRef.current !== null) {
                // Another setExtremes function that resets the min and max values to only show 30 seconds or less (if log is less than 30s in length)
                graphRef.current.chart.xAxis[0].setExtremes(this.state.startTime, (this.state.logTimes[this.state.logTimes.length - 1] - this.state.logTimes[0]) > 30000 ? this.state.logTimes[0] + 30000 : this.state.logTimes[this.state.logTimes.length - 1], true, false);
            }
        });

        if(this.navRef.current !== null) {
            // Same setExtreme as above but for navigator
            this.navRef.current.chart.xAxis[0].setExtremes(this.state.startTime, (this.state.logTimes[this.state.logTimes.length - 1] - this.state.logTimes[0]) > 30000 ? this.state.logTimes[0] + 30000 : this.state.logTimes[this.state.logTimes.length - 1], true, false);
        }

        this.beingUpdated = false;
    };

    // Reset log to show entire log and only shows one channel with all series enabled
    resetToStart = () => {
        this.resetMinMax();

        // Map to reset headers channelToggles back to default
        let headers = this.state.headers.map((headerData, headerIdx) => {
            headerData.channelToggles = [true, false, false, false];
            headerData.minValue = this.state.originalHeadersMinMaxValues[headerIdx].minValue;
            headerData.maxValue = this.state.originalHeadersMinMaxValues[headerIdx].maxValue;

            return headerData;
        });

        this.state.headers.forEach((headerData, headerIdx) => {
            this.channelRef.forEach((singleChannel) => {
                if(singleChannel.current !== null) {
                    singleChannel.current.chart.yAxis[headerIdx].setExtremes(this.state.originalHeadersMinMaxValues[headerIdx].minValue, this.state.originalHeadersMinMaxValues[headerIdx].maxValue, true, false)
                }
            })
        });

        this.setState( {
            headers: headers,
            chartHeight: 1
        })
    };

    /**
     * On mouse move function which keeps tooltip and crosshair in place when mouse is moved off chart.
     * @param {object} e - the event of the mouse move
     */
    _onMouseMove = (e) => {
        this.channelRef.forEach((chart) => {
            if(chart.current == null) {
                return;
            }

            let event = chart.current.chart.pointer.normalize(e);

            for(let i = 0; i < chart.current.chart.series.length; i++) {
                let series = chart.current.chart.series[i];
                if(!series.visible) {
                    continue
                }

                let point = series.searchPoint(event, true);

                if(point == null) {
                    continue
                }

                // Calls Highcharts highlight function
                point.highlight(e);

                break;
            }
        })
    };


    /**
     * Toggle function to enable and disable channels. If no series are toggled in the channel the channel becomes disabled.
     * @param {number} axisIndex - the axisIndex being the number gauge that has been clicked
     * @param {number} channelIndex - the index of channelRefs, therefore the chart which needs to update
     * @param {boolean} channelValue - the new visibility value which will either display or hide the channel
     */
    toggleChannel = (axisIndex, channelIndex, channelValue) => {
        let headers = this.state.headers.map((headerData, headerIdx) => {
            if(headerIdx === axisIndex) {
                headerData.channelToggles[channelIndex] = channelValue
            }

            return headerData;
        });

        let array = [];

        for(let i = 0; i < 4; i++) {
            array.push(this.state.headers.some((header) => {
                return header.channelToggles[i]
            }))
        }

        array = array.filter((val) => { return val });

        this.setState( {
            headers: headers,
            chartHeight: array.length
        });
    };

    /**
     * Toggle function that enables one series to be shown in the navigator
     * @param {object} event - event.value is the index of the series which has been clicked
     */
    toggleNavigatorSeries = (event) => {
        let selectLabel = this.state.headers[event.value].label;

        let navigatorToggles = this.state.navigatorSeriesToggles.map((toggleValues, toggleSeriesIdx) => {
            let newToggleValue = false;
            if (toggleSeriesIdx === event.value) {
                newToggleValue = !toggleValues
            }

            return newToggleValue;
        });

        if (navigatorToggles.some(Boolean)) {
            this.setState({
                navigatorSeriesToggles: navigatorToggles,
                selectLabel: selectLabel
            });
            segment.track('log_navigatorSeriesToggled');
        }
    };

    // Toggles fullscreen in browser
    fullScreenToggler = () => {
        this.setState({isFull: !this.state.isFull})
    };

    // Toggles map to show or not, also hides chart crosshair
    toggleMap = () => {
        if(this.marker){
            this.marker.setMap(null);
            this.marker = null;
        }
        this.setState({toggleMap: !this.state.toggleMap}, () => {
            segment.track('log_mapToggled');
            this.hideCrosshair()
        })
    };

    // Calls chart function to remove and show the crosshair, visually resetting it.
    hideCrosshair() {
        this.channelRef.forEach((graphRef) => {
            if(graphRef.current !== null) {
                // Removes crosshair from chart
                graphRef.current.chart.xAxis[0].hideCrosshair();
                // Update function that disables and re-enables the hover state of the chart
                graphRef.current.chart.update({
                    plotOptions: {
                        series: {
                            marker: {
                                states: {
                                    hover: {
                                        enabled: false
                                    }
                                }
                            }
                        }
                    }
                });
                graphRef.current.chart.update({
                    plotOptions: {
                        series: {
                            marker: {
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        }
                    }
                });
                // Reflows the chart
                graphRef.current.chart.reflow();
            }
        });
    }

    /**
     * Updates the min of an axes, for both the gauges and the charts
     * @param {number} headerIdx - the axes index that is being edited
     * @param {number} newMin - the new minimum value to use in the axis and charts Y axis
     */
    updateMin = (headerIdx, newMin) => {
        let newHeaders = this.state.headers;
        newHeaders[headerIdx].minValue =  parseInt(newMin.target.value);

        this.setState({
            headers: newHeaders
        });

        this.channelRef.forEach((graphRef) => {
            if(graphRef.current !== null) {
                // This setExtremes is for the yAxis and updates the min for all charts
                graphRef.current.chart.yAxis[headerIdx].setExtremes(newMin.target.value, this.state.headers[headerIdx].maxValue, true, false)
            }
        });
    };

    /**
     * Updates the max value to be used in the gauges and charts Y axis
     * @param {number} headerIdx - the axes index that is being edited
     * @param {number} newMax - the new maximum value to use in the axis and charts Y axis
     */
    updateMax = (headerIdx, newMax) => {
        let newHeaders = this.state.headers;
        newHeaders[headerIdx].maxValue = parseInt(newMax.target.value);

        this.setState({
            headers: newHeaders
        });

        this.channelRef.forEach((graphRef) => {
            if(graphRef.current !== null) {
                // This setExtremes is for the yAxis and updates the max for all charts
                graphRef.current.chart.yAxis[headerIdx].setExtremes(this.state.headers[headerIdx].minValue, parseInt(newMax.target.value), true, false)
            }
        });
    };

    /**
     * A toggle to change the axis type to and from gauge and bar
     * @param {boolean} axisBool - the current boolean for an axis (true === bar, false === gauge)
     * @param {number} clickedIndex - the index of the axis that was clicked. To know which axis to change
     */
    changeAxisType = (axisBool, clickedIndex) => {
        let axisType = this.state.axisType;

        axisType[clickedIndex] = axisBool;

        this.setState({
            axisType
        })
    };

    /**
     * Small function to change tab from 1 to 2 and back, to change the tabs. Built to allow more tabs to be built in future
     * @param {number} tab - the tab number that is clicked on
     */
    changeTab = (tab) => {
        this.setState({
            tab
        })
    };

    /**
     * update the colour of the header, changing the series colour, navigator colour and gauge / bar colour
     * @param {string} newColor - the new colour that will be used in the charts and gauges update function
     * @param {number} clickedIndex - the clicked index of the axis, to know which header is being edited
     */
    updateHeaderColor = (newColor, clickedIndex) => {
        let colors = this.state.colors;
        colors[clickedIndex] = newColor;

        this.setState({
            colors,
            openColors: false
        })
    };

    // A small toggle function that changes the state of openColors bool, to toggle the select colours box
    openColors = () => {
        this.setState({
            openColors: !this.state.openColors
        })
    };

    // Function to call to disable the colour box on click of any other div
    closeOpenColors = () => {
        if(this.state.openColors) {
            this.setState({
                openColors: false
            })
        }
    };

    // Small toggle function that updates array of yAxis booleans.
    updateYAxis = (yAxisBool, yAxisIdx) => {
        let yAxisBools = this.state.yAxisBools;

        yAxisBools[yAxisIdx] = yAxisBool;

        this.setState({
            yAxisBools
        })
    };

    // Toggle function that changes drag or zoom boolean in state
    updateDragOrZoom = () => {
        this.setState({
            dragOrZoom: !this.state.dragOrZoom
        })
    };

    render() {
        return (
            <FullScreen
                enabled={this.state.isFull}
                onChange={isFull => this.setState({isFull})}>
                <main>
                    { this.state.logId &&
                        <div>
                            <div className={styles.dataLogContainer}>
                                <div className={`${styles.columnContainer} bgSecondary highlightTertiary`}>
                                    <HeadButtons
                                        fullScreen={this.state.isFull}
                                        fullScreenToggler={this.fullScreenToggler}
                                        hints={this.state.hints}
                                    />

                                    <Axes
                                        axisType={this.state.axisType}
                                        bgPrimary={this.bgPrimary}
                                        bgSecondary={this.bgSecondary}
                                        bgTertiary={this.bgTertiary}
                                        changeAxisType={this.changeAxisType}
                                        changeTab={this.changeTab}
                                        clickedIndex={this.state.clickedIndex}
                                        closeOpenColors={this.closeOpenColors}
                                        colors={this.state.colors}
                                        colorsSelection={this.state.colorsSelection}
                                        gaugeRefs={this.gaugeRefs}
                                        headers={this.state.headers}
                                        hints={this.state.hints}
                                        logName={this.state.logName}
                                        logParamData={this.state.logParamData}
                                        openColors={this.state.openColors}
                                        originalMinMaxValues={this.state.originalHeadersMinMaxValues}
                                        seriesToggle={this.seriesToggle}
                                        tab={this.state.tab}
                                        toggleChannel={this.toggleChannel}
                                        toggleOpenColors={this.openColors}
                                        updateHeaderColor={this.updateHeaderColor}
                                        updateMin={this.updateMin}
                                        updateMax={this.updateMax}
                                        updateYAxis={this.updateYAxis}
                                        yAxisBools={this.state.yAxisBools}
                                    />
                                </div>

                                <div className={styles.channelAndNavContainer}>
                                    <ButtonToolbar
                                        colors={this.state.colors}
                                        dragOrZoom={this.state.dragOrZoom}
                                        logId={this.state.logId}
                                        isMapEnabled={this.state.toggleMap}
                                        headers={this.state.headers}
                                        hints={this.state.hints}
                                        navigatorSeriesToggles={this.state.navigatorSeriesToggles}
                                        resetMinMax={this.resetMinMax}
                                        resetToStart={this.resetToStart}
                                        selectLabel={this.state.selectLabel}
                                        setFullMinMax={this.setFullMinMax}
                                        showMapButton={this.state.logMapData.length > 0}
                                        title={this.state.logName}
                                        toggleMap={this.toggleMap}
                                        toggleNavigatorSeries={this.toggleNavigatorSeries}
                                        updateDragOrZoom={this.updateDragOrZoom}
                                    />

                                <Channels
                                    _onMouseMove={this._onMouseMove}
                                    addPlotLine={this.addPlotLine}
                                    bgPrimary={this.bgPrimary}
                                    bgSecondary={this.bgSecondary}
                                    bgTertiary={this.bgTertiary}
                                    channelRef={this.channelRef}
                                    chartHeight={this.state.chartHeight}
                                    clickedIndex={this.state.clickedIndex}
                                    colors={this.state.colors}
                                    dragOrZoom={this.state.dragOrZoom}
                                    endTime={this.state.endTime}
                                    headers={this.state.headers}
                                    hints={this.state.hints}
                                    logMapData={this.state.logMapData}
                                    logParamData={this.state.logParamData}
                                    logTimes={this.state.logTimes}
                                    mapMarker={this.mapMarker}
                                    mapWidth={this.state.mapWidth + 5}
                                    newMax={this.state.newMax}
                                    newMin={this.state.newMin}
                                    setTooltipValues={this.setTooltipValues}
                                    setMinMax={this.setMinMax}
                                    setMapMarker={this.setMapMarker}
                                    startTime={this.state.startTime}
                                    tab={this.state.tab}
                                    toggleMap={this.state.toggleMap}
                                    unitArray={this.state.unitArray}
                                    yAxisBools={this.state.yAxisBools}
                                />

                                {this.state.logMapData.length > 0 &&
                                    this.state.toggleMap &&
                                    <Rnd
                                        className={styles.draggable}
                                        default={{
                                            width: this.state.mapWidth,
                                            y: 100
                                        }}
                                        disableDragging={true}
                                        enableResizing={{
                                            top: false,
                                            right: false,
                                            bottom: false,
                                            left: true,
                                            topRight: false,
                                            bottomRight: false,
                                            bottomLeft: false,
                                            topLeft: false
                                        }}
                                        minHeight={"calc(100% - 56px)"}
                                        minWidth={400}
                                        maxHeight={"calc(100% - 56px)"}
                                        maxWidth={770}
                                        onResize={(e, direction, ref, delta, position) => {
                                            this.hideCrosshair();
                                            this.setState({
                                                mapWidth: this.state.mapWidthStart + delta.width,
                                            })
                                        }}
                                        onResizeStart={() => {
                                            this.setState({
                                                updateMap: true,
                                                mapWidthStart: this.state.mapWidth
                                            })
                                        }}
                                        onResizeStop={() => {
                                            this.setState({
                                                updateMap: false,
                                            });
                                        }}
                                        resizeHandleStyles={{
                                            left: {"width": "20px", "left": "-10px", "zIndex": "1"}
                                        }}
                                        style={{"height": "calc(100% - 150px)", "background": this.bgTertiary, "left": "auto", "right": "0", "top": "auto", "bottom": "0", "display": this.state.toggleMap ? "block" : "none", "borderLeft": "1px solid #444", "zIndex": "1"}}
                                    >
                                        <MapViewer
                                            chartHeight={this.state.chartHeight}
                                            containerElement={<div style={{display: this.state.toggleMap ? "block" : "none" , height: `100%`, width: this.state.mapWidth, position: `absolute`, top: `0`, right: `0`, zIndex: `1` }} />}
                                            googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyCYTEObt9NEB5S6PXS2DcFyteq_Yp4NFeM"}
                                            headers={this.state.headers}
                                            loadingElement={<div style={{ height: `100%`, width: this.state.mapWidth, position: `absolute`, top: `0`, right: `0`, zIndex: `1` }} />}
                                            logMapData={this.state.logMapData}
                                            logParamData={this.state.logParamData}
                                            mapElement={<div id="myMapId" data-hint={this.state.hints.map} style={{ height: `100%`, width: this.state.mapWidth, position: `absolute`, top: `0`, right: `0`, zIndex: `1` }} />}
                                            navigatorSeriesToggles={this.state.navigatorSeriesToggles}
                                            setMapRef={(ref)=>{ this.mapRef = ref; }}
                                            toggleMap={this.state.toggleMap}
                                            updateMap={this.state.updateMap}
                                        />
                                    </Rnd>
                                    }

                                    <Navigator
                                        bgPrimary={this.bgPrimary}
                                        bgSecondary={this.bgSecondary}
                                        bgTertiary={this.bgTertiary}
                                        clickedIndex={this.state.clickedIndex}
                                        colors={this.state.colors}
                                        dataLog={this.state}
                                        hints={this.state.hints}
                                        mapWidth={this.state.mapWidth + 5}
                                        navigatorSeriesToggles={this.state.navigatorSeriesToggles}
                                        navRef={this.navRef}
                                        setMinMax={this.setMinMax}
                                        toggleMap={this.state.toggleMap}
                                        openColors={this.state.openColors}
                                    />
                                </div>
                            </div>
                            <UtilityBar hints={this.state.hints} />
                        </div>
                    }

                    <Mobile />
                </main>
            </FullScreen>
        )
    }
}

export default Viewer;
