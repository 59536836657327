import React, { Component } from 'react';
import { Context } from "context/context";

import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock'
import equal from "fast-deep-equal";
import clone from 'clone-deep'

import styles from './graph.module.scss'

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    },
});

class Graph extends Component {
    static contextType = Context;

    state = {
        colors: clone(this.props.colors),
        tooltipValues: [],
        options: {
            series: [],
            yAxis: []
        },
        updated: false,
        yAxisBools: clone(this.props.yAxisBools),
    };

    chart = React.createRef();

    componentDidMount() {
        window.addEventListener('wheel', this.handleScroll);

        this.setState({
            options: {
                chart: {
                    animation: false,
                    backgroundColor: this.props.bgPrimary,
                    panning: {
                        enabled: !this.props.dragOrZoom,
                    },
                    panKey: 'alt',
                    resetZoomButton: {
                        theme: {
                            display: 'none'
                        }
                    },
                    style: {
                        color: '#fff'
                    },
                    events: {
                        click: this.props.addPlotLine
                    },
                    zoomType: this.props.dragOrZoom ? 'x' : undefined,
                    spacingTop: 0,
                    spacingRight: 0,
                    spacingBottom: 0,
                    spacingLeft: 0,
                },
                credits: {
                    enabled: false,
                },
                legend: {
                    enabled: false,
                },
                navigator: {
                    enabled: false,
                    outlineWidth: 0,
                },
                plotOptions: {
                    series: {
                        animation: false,
                        dataGrouping: {
                            enabled: true,
                            groupPixelWidth: 1,
                            smoothed: true,
                        },
                        marker: {
                            enabled: false,
                        },
                        point: {
                            events: {
                                mouseOver: this.onMouseOver.bind(this),
                            }
                        },
                        states: {
                            hover: {
                                lineWidthPlus: 0
                            },
                            inactive: {
                                opacity: 1
                            }
                        },
                        turboThreshold: 0
                    },
                },
                rangeSelector: {
                    enabled: false,
                },
                scrollbar: {
                    buttonArrowColor: 'transparent',
                    enabled: true,
                    height: 0,
                    liveRedraw: true,
                },
                series: this.totalSeries(this.props.logTimes, this.props.logParamData, this.props.headers, this.props.logMapData),
                title: {
                    style: {
                        color: "#fff"
                    },
                    text: '',
                },
                tooltip: {
                    split: true,
                },
                xAxis: [{
                    crosshair: {
                        label: {
                            enabled: true,
                            formatter: (value) => {
                                return `${((value - this.props.startTime) / 1000).toFixed(2)}`
                            }
                        },
                    },
                    events: {
                        afterSetExtremes: (event) => {
                            this.setMinMax(event)
                        },
                    },
                    labels: {
                        formatter: (value) => {
                            return `${((value.value - this.props.startTime) / 1000).toFixed(2)}`
                        },
                        style: {
                            color: "#fff"
                        }
                    },
                    max: this.props.newMax,
                    min: this.props.newMin,
                }],
                yAxis: this.headers(this.props.headers),
            }
        }, () => {
            this.setBackground();
            this.setAxis();
        })
    }

    handleScroll = (e) => {
        if(e.path){
            const eventContainsHighchartsNavigator = e.path.some(elem => elem?.classList?.contains("highcharts-container"));
            if(eventContainsHighchartsNavigator) {
                let change;

                change = e.deltaY > 0;

                this.updateMinMax(change)
            }
        }
    };

    updateMinMax = (change) => {
        if(this.props.graphRef.current !== null) {
            let factor = 0.90;
            if(change) factor = 1.1;

            let range = this.props.graphRef.current.chart.xAxis[0].max - this.props.graphRef.current.chart.xAxis[0].min;
            const offset = (range * factor) - range;

            let min = (this.props.graphRef.current.chart.xAxis[0].min - (offset / 2));
            let max = (this.props.graphRef.current.chart.xAxis[0].max + (offset / 2));

            if(min < this.props.startTime) min = this.props.startTime;
            if(max > this.props.endTime) max = this.props.endTime;
            this.props.setMinMax(this.props.graphRef, min, max, true);
            this.removeAndResetCrosshair();
        }
    };

    removeAndResetCrosshair() {
        // Remove and reset crosshair and markers
        this.props.graphRef.current.chart.xAxis[0].hideCrosshair();
        this.props.graphRef.current.chart.update({
            plotOptions: {
                series: {
                    marker: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                }
            }
        });

        this.props.graphRef.current.chart.update({
            plotOptions: {
                series: {
                    marker: {
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                }
            }
        });

        this.props.graphRef.current.chart.reflow();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check to see if channel toggles have changed, meaning a series has been made (in)visible
        if(!equal(prevProps.channelToggles, this.props.channelToggles)) {
            this.props.channelToggles.forEach((toggleValue, toggleIdx) => {
                if(prevProps.channelToggles[toggleIdx] !== toggleValue) {
                    this.props.graphRef.current.chart.series[toggleIdx].update({
                        visible: toggleValue
                    });
                }
            })
        }

        // Check to see if channel count has changed to update the background colour
        if(prevProps.count !== this.props.count) {
            this.setBackground()
        }

        // Check to see if graph is last in list, to enable or hide the xAxis
        if(prevProps.last !== this.props.last) {
            this.setAxis()
        }

        // check for chart height (number of graphs), clears crosshair.
        if(prevProps.chartHeight !== this.props.chartHeight) {
            this.removeAndResetCrosshair()
        }

        // If colours change from axisDetails, sets new colours in chart
        if(!equal(this.state.colors, this.props.colors)) {
            if(this.props.graphRef.current !== null) {
                this.props.graphRef.current.chart.series[this.props.clickedIndex].update({
                    color: this.props.colors[this.props.clickedIndex]
                });

                this.props.graphRef.current.chart.yAxis[this.props.clickedIndex].update({
                    labels: {
                        style: {
                            color: this.props.colors[this.props.clickedIndex]
                        }
                    },
                });

                this.setState({
                    colors: clone(this.props.colors)
                })
            }
        }

        // If a yaxis has been toggled update
        if(!equal(this.state.yAxisBools, this.props.yAxisBools)) {
            if(this.props.graphRef.current !== null) {
                this.props.graphRef.current.chart.yAxis[this.props.clickedIndex].update({
                    labels: {
                        enabled: this.props.yAxisBools[this.props.clickedIndex]
                    },
                });

                this.setState({
                    yAxisBools: clone(this.props.yAxisBools)
                }, () => {
                    this.removeAndResetCrosshair()
                })

            }
        }

        // Check if toggle changes
        if(this.props.dragOrZoom !== prevProps.dragOrZoom) {
            if(this.props.graphRef.current !== null) {
                this.props.graphRef.current.chart.update({
                    chart: {
                        panning: {
                            enabled: !this.props.dragOrZoom
                        },
                        zoomType: this.props.dragOrZoom ? 'x' : undefined
                    }
                });
            }
        }

        // If the tab changes either return point formatter or not
        if(this.props.tab !== prevProps.tab) {
            let pointFormatter = null;
            if(this.props.tab === 1) pointFormatter = function () {
                return "";
            };
            if(this.props.graphRef.current !== null) {
                this.props.headers.forEach((singleHeader, headerIdx) => {
                    this.props.graphRef.current.chart.series[headerIdx].update({
                        tooltip: {
                            pointFormatter
                        }
                    })
                });

                this.removeAndResetCrosshair()
            }
        }
    }

    // Sets the background color for the chart on mount and update
    setBackground = () => {
        this.props.graphRef.current.chart.update({
            chart: {
                backgroundColor: this.props.count % 2 ? this.props.bgPrimary : this.props.bgSecondary
            }
        });
    };

    //Sets the Xaxis visibility for the chart on mount and update
    setAxis = () => {
        this.props.graphRef.current.chart.xAxis[0].update({
            visible: this.props.last,
        });
    };

    /**
     * Gets chart min max and passes values up to viewer to set graph and nav extremes
     * @param {object} e - chart object that the min and max values are taken from, and set in the extremes
     */
    setMinMax = (e) => {
        this.props.setMinMax(this.props.graphRef, e.min, e.max);
        this.removeAndResetCrosshair()
    };

    /**
     * Sets the series values to use on the chart
     * @param {array} logTimes - array of logtime x-values
     * @param {array} paramData - all Y values for the chart series
     * @param {array} headerData - the Y axis object containing the axis meta data
     * @param {array} logMapData - the Map data array containing long-lat values
     */
    totalSeries = (logTimes, paramData, headerData, logMapData) => {
        let showInNavigator = false;
        let valueDecimals = 0;

        return paramData.map((paramArray, arrayIdx) => {
            showInNavigator = headerData[arrayIdx].label === "Engine RPM";

            paramArray.forEach((insideParamArray) => {
                if(insideParamArray !== Math.floor(insideParamArray)){
                    valueDecimals = 2;
                } else {
                    valueDecimals = 0;
                }
            });

            let data = paramArray.map((paramValue, valueIdx) => {
                return {x: logTimes[valueIdx], y: paramValue, geoInfo: logMapData[valueIdx]}
            });

            let pointFormatter = null;
            if(this.props.tab === 1) pointFormatter = function () {
                return "";
            };

            return {
                animation: false,
                color: this.props.colors[arrayIdx],
                data: data,
                id: arrayIdx,
                name: headerData[arrayIdx].label,
                showInNavigator: showInNavigator,
                tooltip: {
                    valueDecimals: valueDecimals,
                    valueSuffix: this.props.unitArray[arrayIdx].customUnit ? this.props.unitArray[arrayIdx].customUnit : this.props.unitArray[arrayIdx].unit,
                    pointFormatter
                },
                type: "spline",
                visible: this.props.channelToggles[arrayIdx],
                yAxis: arrayIdx,
            };
        });
    };

    /**
     * Sets the Yaxis options for the chart
     * @param {array} headerData - the array containing the meta data for each Yaxis header
     */
    headers = (headerData) => {
        return headerData.map((headerObject, headerIdx) => {
            return {
                allowDecimals: false,
                gridLineColor: this.context.lightTheme ? '#d0d0d0' : '#444',
                gridLineWidth: 1,
                labels: {
                    enabled: this.props.yAxisBools[headerIdx],
                    style: {
                        color: this.props.colors[headerIdx]
                    }
                },
                max: headerObject.maxValue,
                min: headerObject.minValue,
                opposite: false,
                title: {
                    style: {
                        color: this.props.colors[headerIdx]
                    },
                    text: ''
                },
                visible: true,
                zoomEnabled: this.props.dragOrZoom,
            }
        });
    };

    /**
     * Function called to update tooltip values in viewer and also update map marker if enabled
     * @param {object} e - event object when mouse moves over chart
     */
    onMouseOver = (e) => {
        this.setTooltipValues(e);
        if(this.props.isMapEnabled && this.props.isMapData) {
            this.props.setMapMarker(e)
        }
    };

    /**
     * Creates a new array of tooltip values and passes up array and x value up to viewer function
     * @param {object} e - object containing chart options that can be used, like x and y value
     */
    setTooltipValues = (e) => {
        let index = this.props.logTimes.map((logTime) => {
            return e.target.x > logTime
        }).indexOf(false);

        let tooltipValues = this.props.logParamData.map(logData => logData[index]);

        this.props.setTooltipValues(tooltipValues);
    };

    render() {
        return (
            this.state.options.series.length !== 0 &&
                <HighchartsReact
                    ref={this.props.graphRef}
                    highcharts={Highcharts}
                    constructorType={"stockChart"}
                    containerProps={{ className: `${styles.channelSingleContainer} ${styles[`chartHeight${this.props.chartHeight}`]}` }}
                    allowChartUpdate={false}
                    options = {this.state.options}
               />
        )
    }
}

export default Graph;
