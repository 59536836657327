import React, { Component } from 'react';

import styles from "./UtilityBar.module.scss";
import Hints from "./hints/Hints";

export default class UtilityBar extends Component {
    render() {
        return (
            <div data-hint={this.props.hints.hintBar} className={`${styles.utilityBar} highlightTertiary bgPrimary`}>
                <Hints/>
            </div>
        )
    }
}