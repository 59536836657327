import React, { Component } from 'react';
import { Context } from './context/context';
import Cookies from 'js-cookie'

import Viewer from "./viewer/Viewer";

import 'styles/themed-styles.scss';

class App extends Component {
    static contextType = Context;

    componentDidMount() {
        this.context.setLightTheme(JSON.parse(Cookies.get('lightTheme')))
    }

    render() {
        return (
            <div className={`app ${this.context.lightTheme ? "light" : "dark"}`}>
                <Viewer />
            </div>
        );
    }
}

export default App;
