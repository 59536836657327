import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ContextProvider from './context/contextProvider';
import * as Sentry from "@sentry/browser"
import Config from "./Config";

if (Config.environment !== "develop")
    Sentry.init({
        environment: Config.environment,
        release: `sw-web-diagnostics@${Config.version}`,
        dsn: "https://214fa6d6531c40c7b1aa592eaf0e0ae9@o368278.ingest.sentry.io/5294723",
        debug: Config.environment !== "production"
    });

ReactDOM.render(
    <ContextProvider>
        <App />
    </ContextProvider>,
    document.getElementById('root')
);