import React, { Component } from 'react';
import { Context } from 'context/context';

import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highstock'

import clone from "clone-deep";
import equal from "fast-deep-equal";

import styles from './navigator.module.scss'

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    },
});

class Navigator extends Component {
    static contextType = Context;

    state = {
        colors: clone(this.props.colors),
        options: {
            series: [],
            yAxis: []
        },
        updated: false,
    };

    componentDidMount() {
        this.setState({
            options: {
                chart: {
                    backgroundColor: this.props.bgPrimary,
                    resetZoomButton: {
                        theme: {
                            display: 'none'
                        }
                    },
                    style: {
                        color: '#fff'
                    },
                },
                credits: {
                    enabled: false,
                },
                legend: {
                    enabled: false,
                },
                navigator: {
                    height: 60,
                    xAxis: {
                        labels: {
                            formatter: (value) => {
                                return `${((value.value - this.props.dataLog.startTime) / 1000).toFixed(2)}`
                            }
                        },
                    },
                    outlineWidth: 0,
                    series: {
                        dataGrouping: {
                            groupPixelWidth: 20
                        },
                    },
                    handles: {
                        height: 35,
                        width: 10,
                    }
                },
                rangeSelector: [{
                    enabled: false,
                }],
                scrollbar: {
                    buttonArrowColor: 'transparent',
                    enabled: true,
                    height: 0,
                    liveRedraw: true,
                },
                series: this.totalSeries(this.props.dataLog.logTimes, this.props.dataLog.logParamData, this.props.dataLog.headers),
                tooltip: {
                    enabled: false,
                },
                xAxis: [{
                    events: {
                        setExtremes: (event) => {
                            this.setMinMax(event)
                        },
                    },
                    labels: {
                        enabled: false
                    },
                    lineWidth: 0,
                    tickLength: 0,
                    max: (this.props.dataLog.endTime - this.props.dataLog.startTime) > 30000 ? this.props.dataLog.startTime + 30000 : this.props.dataLog.endTime,
                    min: this.props.dataLog.startTime,
                }],
                yAxis: this.headers(this.props.dataLog.headers),
            }
        })
    }

    /**
     * Sets the series values to use on the chart
     * @param {array} logTimes - array of logtime x-values
     * @param {array} paramData - all Y values for the chart series
     * @param {array} headerData - the Y axis object containing the axis meta data
     */
    totalSeries = (logTimes, paramData, headerData) => {
        let valueDecimals = 0;

        return paramData.map((paramArray, arrayIdx) => {
            paramArray.forEach((insideParamArray) => {
                if(insideParamArray !== Math.floor(insideParamArray)){
                    valueDecimals = 2;
                } else {
                    valueDecimals = 0;
                }
            });

            return {
                color: this.props.colors[arrayIdx],
                data: paramArray.map((paramValue, valueIdx) => [logTimes[valueIdx], paramValue]),
                lineWidth: 0,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                name: headerData[arrayIdx].label,
                showInNavigator: this.props.navigatorSeriesToggles[arrayIdx],
                tooltip: {
                    valueDecimals: valueDecimals
                },
                type: "line",
                visible: true,
                yAxis: arrayIdx,
            };
        });
    };

    /**
     * Sets the Yaxis options for the chart
     * @param {array} headerData - the array containing the meta data for each Yaxis header
     */
    headers = (headerData) => {
        return headerData.map((headerObject, headerIdx) => {
            return {
                allowDecimals: false,
                gridLineColor: '#2e2e2e',
                gridLineWidth: 0,
                height: 0,
                labels: {
                    enabled: false
                },
                opposite: false,
                title: {
                    style: {
                        color: this.props.colors[headerIdx]
                    },
                    text: `${headerObject.label} (${headerObject.secondaryLabel})`
                },
                visible: false
            }
        });
    };

    /**
     * Sends min max values up to viewer to be set in chart options
     * @param {object} e - chart object that the min and max values are taken from, and set in the extremes
     */
    setMinMax = (e) => {
        this.props.setMinMax(this.props.navRef, e.min, e.max)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.navigatorSeriesToggles !== this.props.navigatorSeriesToggles) {
            this.props.navigatorSeriesToggles.forEach((toggleValue, toggleIdx) => {
                this.props.navRef.current.chart.series[toggleIdx].update({
                    showInNavigator: toggleValue
                })
            })
        }

        if(!equal(this.state.colors, this.props.colors)) {
            if(this.props.navRef.current !== null) {
                this.props.navRef.current.chart.series[this.props.clickedIndex].update({
                    color: this.props.colors[this.props.clickedIndex]
                });

                this.setState({
                    colors: clone(this.props.colors)
                })
            }
        }

        if(this.props.toggleMap !== prevProps.toggleMap) {
            if(this.props.navRef.current !== null) {
                this.props.navRef.current.chart.reflow();
            }
        }

        if(this.props.mapWidth !== prevProps.mapWidth) {
            if(this.props.navRef.current !== null) {
                this.props.navRef.current.chart.reflow();
            }
        }
    }

    render() {
        let styleObject = {
            width: 'calc(100% - ' + this.props.mapWidth + 'px)',
        };

        return (
            this.state.options.series.length !== 0 &&
                <div className={`${styles.navigatorContainer} highlightTertiary`} style={this.props.toggleMap ? styleObject : {}} data-hint={this.props.hints.navigator}>
                    <HighchartsReact
                        ref={this.props.navRef}
                        highcharts={Highcharts}
                        constructorType={"stockChart"}
                        containerProps={{ className: styles.navigatorGlobal }}
                        allowChartUpdate={false}
                        options = {this.state.options}
                    />
                </div>
        )
    }
}

export default Navigator;
