import * as React from 'react';
import { Context } from './context';
import Config from "Config";

import * as Requests from 'data/requests';

export default class ContextProvider extends React.Component {

    state = {
        getLogs: this.getLogs,
        setLightTheme: this.setLightTheme.bind(this),
        lightTheme: false
    };

    getLogs(id){
        return new Promise((resolve, reject) => {
            Requests.getLogs(id).then((res) => {
                resolve(res)
            }).catch((error) => {
                window.location.href = Config.dashboard;
            });
        })
    };

    setLightTheme(theme){
        this.setState({
            lightTheme: theme
        })
    }

    render() {
        return (
            <Context.Provider value={{ ...this.state }}>
                {this.props.children}
            </Context.Provider>
        )
    }
}