import React, {Component} from 'react';

import Axis from "./axis/Axis";
import AxisDetails from "./axisDetails/AxisDetails";

import styles from './axes.module.scss';

class Axes extends Component {
    render() {
        let headerRemainder = 10 - this.props.headers.length;

        return (
            <>
                <div className={`${styles.axesMeta} bgTertiary`}>
                    <div className={styles.axesTabs}>
                        <button className={`${this.props.tab === 1 ? styles.active : undefined} bgTertiary highlightTertiary`} onClick={this.props.changeTab.bind(this, 1)} data-hint={this.props.hints.axesTab}>Axes</button>
                        <button className={`${this.props.tab === 2 ? styles.active : undefined} bgTertiary highlightTertiary`} onClick={this.props.changeTab.bind(this, 2)} data-hint={this.props.hints.settingsTab}>Settings</button>
                    </div>
                </div>
                <div className={`${this.props.tab === 1 ? styles.padding : undefined } ${styles.axesContainer} bgTertiary`}>
                    {this.props.tab === 1 ?
                        <>
                            {this.props.headers.map((headerData, headerIdx) => {
                                let styleObject = {
                                    border: `1px solid ${this.props.colors[headerIdx]}`,
                                };

                                return (
                                    <div key={headerIdx} style={styleObject} className={`bgSecondary ${styles.axisSingleContainer}`} data-hint={this.props.hints.axis}>
                                        <Axis
                                            axisType={this.props.axisType[headerIdx]}
                                            bgPrimary={this.props.bgPrimary}
                                            bgSecondary={this.props.bgSecondary}
                                            clickedIndex={this.props.clickedIndex}
                                            colors={this.props.colors}
                                            firstValue={this.props.logParamData[headerIdx][0]}
                                            gaugeRef={this.props.gaugeRefs[headerIdx]}
                                            index={headerIdx}
                                            key={headerIdx}
                                            originalMin={this.props.originalMinMaxValues[headerIdx].minValue}
                                            originalMax={this.props.originalMinMaxValues[headerIdx].maxValue}
                                            paramMetaData={headerData}
                                            seriesChannelValues={this.props.headers[headerIdx].channelToggles}
                                        />
                                    </div>
                                )
                            })}

                            {[...Array(headerRemainder).keys()].map((data, index) => {
                                return (
                                    <div className={`bgSecondary ${styles.axisSingleContainer} ${styles.noDrag}`} key={index} data-hint={this.props.hints.disabledAxis}>
                                        <span className="highlightTertiary"/>
                                    </div>
                                )
                            })}
                        </>
                    :
                        <div className={styles.headerContainer}>
                            {this.props.headers.map((headerData, headerIdx) => {
                                return (
                                    <div key={headerIdx} style={{border: `1px solid ${this.props.colors[headerIdx]}`}} className={`${styles[`headerDetailsContainer-${headerIdx + 1}`]} ${styles.headerDetailsContainer} bgTertiary`} onClick={() => {this.props.seriesToggle(headerIdx); this.props.closeOpenColors()}}>
                                        <AxisDetails
                                            axisType={this.props.axisType[headerIdx]}
                                            changeAxisType={this.props.changeAxisType}
                                            clickedIndex={this.props.clickedIndex}
                                            colors={this.props.colors}
                                            colorsSelection={this.props.colorsSelection}
                                            headerDetails={this.props.headers[headerIdx]}
                                            hints={this.props.hints}
                                            index={headerIdx}
                                            openColors={this.props.openColors}
                                            originalMin={this.props.originalMinMaxValues[headerIdx].minValue}
                                            originalMax={this.props.originalMinMaxValues[headerIdx].maxValue}
                                            toggleChannel={this.props.toggleChannel}
                                            toggleOpenColors={this.props.toggleOpenColors}
                                            updateHeaderColor={this.props.updateHeaderColor}
                                            updateMin={this.props.updateMin}
                                            updateMax={this.props.updateMax}
                                            updateYAxis={this.props.updateYAxis}
                                            yAxisType={this.props.yAxisBools[headerIdx]}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default Axes;
