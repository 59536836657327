import React, { Component } from 'react';
import { Context } from 'context/context';
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts/highcharts'
import more from "highcharts/highcharts-more"
import solidGauge from "highcharts/modules/solid-gauge.js";
import './styles/axis.scss'

more(Highcharts);
solidGauge(Highcharts);

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    },
});


class Axis extends Component {
    static contextType = Context;

    state = {
        options: {
            chart: {
                animation: false,
                backgroundColor: this.props.bgSecondary,
                marginBottom: this.props.axisType ? 30 : undefined,
                marginTop: this.props.axisType ? 50 : undefined,
                style: {
                    opacity: '1',
                },
                type: this.props.axisType ? 'bar': 'solidgauge',
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false
            },
            pane: {
                background: {
                    backgroundColor: this.props.bgPrimary,
                    borderWidth: this.context.lightTheme ? 1 : 0,
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc',
                },
                center: ['50%', '85%'],
                endAngle: 90,
                size: '100%',
                startAngle: -90,
            },
            plotOptions: {
                bar: {
                    borderColor: this.props.colors[this.props.index],
                    color: this.props.colors[this.props.index],
                    dataLabels: {
                        enabled: true,
                    }
                },
                series: {
                    animation: {
                        duration: 100,
                    },
                    dataLabels: {
                        style: {
                            color: this.context.lightTheme ? '#333' : '#fff',
                            fontSize: '15px',
                            textOutline: '#00000000',
                        }
                    },
                    states: {
                        hover: {
                            enabled: false,
                        }
                    },
                },
                solidgauge: {
                    dataLabels: {
                        borderWidth: 0,
                        y: 5,
                    },
                    scrollbar: {
                        enabled: false,
                    },
                    borderWidth: 1,
                },
            },
            series: [],
            title: null,
            tooltip: {
                enabled: false
            },
            xAxis: {
                labels: {
                    enabled: false
                },
                lineWidth: 0,
                minorGridLineWidth: 0,
                minorTickLength: 0,
                tickLength: 0
            },
            yAxis: {
                labels: {
                    distance: -15,
                    style: {
                        color: '#bdbdbd',
                    }
                }
            },
        }
    };

    componentDidMount() {
        this.chart = React.createRef();

        this.setState({
            options: {
                ...this.state.options,
                series: [{
                    data: [parseFloat(this.props.firstValue.toFixed(2))],
                }],
                style: {
                    color: this.props.colors[this.props.index]
                },
                yAxis: {
                    labels: {
                        y: 20,
                    },
                    lineWidth: 0,
                    max: this.props.paramMetaData.maxValue,
                    min: this.props.paramMetaData.minValue,
                    minorTicks: false,
                    minorTickInterval: null,
                    stops: [
                        [0, this.props.colors[this.props.index]],
                    ],
                    tickPositions: [this.props.paramMetaData.minValue, this.props.paramMetaData.maxValue],
                    tickWidth: 0,
                    title: {
                        text: this.props.paramMetaData.userPreferredUnit ? `${this.props.paramMetaData.label} (${this.props.paramMetaData.userPreferredUnit})` : this.props.paramMetaData.secondaryLabel ? `${this.props.paramMetaData.label} (${this.props.paramMetaData.secondaryLabel})` : `${this.props.paramMetaData.label} (${this.props.paramMetaData.unit})`,
                        style: {
                            color: this.context.lightTheme ? '#333' : '#fff',
                        },
                        useHTML: true,
                        textAlign: "center",
                    },
                },
            },
        });

        this.updateOpacity()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateOpacity();
    }

    /**
     * Update function that is called to check and change the colour of axis titles
     */
    updateOpacity = () => {
        if(!this.props.seriesChannelValues.some(Boolean)) {
            // Update function to change opacity of gauge depending if they appear on a chart
            this.props.gaugeRef.current.chart.update({
                chart: {
                    style: {
                        opacity: '0.6'
                    }
                },
                yAxis: {
                    title: {
                        style: {
                            opacity: '0.6'
                        }
                    }
                }
            })
        } else {
            // Update function to change opacity of gauge depending if they appear on a chart
            this.props.gaugeRef.current.chart.update({
                chart: {
                    style: {
                        opacity: '1'
                    }
                },
                yAxis: {
                    title: {
                        style: {
                            opacity: '1'
                        }
                    }
                }
            })
        }
    };

    render() {
        return (
            <HighchartsReact
                containerProps={{ className: `axisGaugeContainer axisGaugeContainer-${this.props.index+1}` }}
                highcharts={Highcharts}
                options={this.state.options}
                ref={this.props.gaugeRef}
            />
        )
    }
}

export default Axis;
