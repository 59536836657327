import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Polyline, Marker } from "react-google-maps"

class MapViewer extends Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // Check if map is being resized
        return this.props.updateMap
        // If navigator and polyline changes update map
        return this.props.navigatorSeriesToggles !== nextProps.navigatorSeriesToggles
    }

    componentDidMount() {
        this.newBounds();
    }

    /**
     * initial function that runs that has access to this.map. Can perform changes and map functions.
     * Currently sets bounds to view the map in based on the NW SE coords
     */
    init = () => {
        if(!this.props.updateMap) {
            this.newBounds()
        }
    };

    /**
     * Function that provides the bounds for the map, meaning that it will always fill as much space as possible
     * the map will zoom in as much as it can to fit in the polyline
     */
    newBounds() {
        if(!this.map) return;
        const bounds = new window.google.maps.LatLngBounds();
        this.props.logMapData.forEach((coord) => {
            bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
        });
        this.map.fitBounds(bounds);
    }

    /**
     * Function that calculates shades between two colours, used to get a gradient
     */
    getGradientColor = (color1, color2, percent) => {
        let newColor = {};
        function makeChannel(a, b) {
            return(a + Math.round((b-a)*(percent/100)));
        }
        function makeColorPiece(num) {
            num = Math.min(num, 255);   // not more than 255
            num = Math.max(num, 0);     // not less than 0
            let str = num.toString(16);
            if (str.length < 2) {
                str = "0" + str;
            }
            return(str);
        }
        newColor.r = makeChannel(color1.r, color2.r);
        newColor.g = makeChannel(color1.g, color2.g);
        newColor.b = makeChannel(color1.b, color2.b);
        newColor.cssColor = "#" +
            makeColorPiece(newColor.r) +
            makeColorPiece(newColor.g) +
            makeColorPiece(newColor.b);
        return(newColor);
    };

    /**
     * Finds multiple coordinates between two points. used to create a more convincing gradient on the line
     */
    getMoreCoords = (coord1, coord2, percent) => {
        function makeChannel(a, b) {
            return(a + (b-a)*(percent/100));
        }

        return {
            lat: makeChannel(coord1.lat, coord2.lat),
            lng: makeChannel(coord1.lng, coord2.lng),
        }
    };

    render() {
        let centerOfLog = Math.round(this.props.logMapData.length / 2);

        let seriesIndex = this.props.navigatorSeriesToggles.indexOf(true);
        let paramMin = Math.min(...this.props.logParamData[seriesIndex]);
        let paramMax = Math.max(...this.props.logParamData[seriesIndex]);

        const styles = require('./mapStyles');

        return (
            <GoogleMap
                ref={(ref) => { this.map = ref; this.init(); this.props.setMapRef(ref)}}
                defaultZoom={2}
                center={this.props.logMapData[centerOfLog]}
                defaultCenter={this.props.logMapData[0]}
                defaultOptions={{styles, fullscreenControl: false, mapTypeControl: false, zoomControl: false, streetViewControl: false}}
            >
                {this.props.logMapData.slice(0, this.props.logMapData.length - 1).flatMap((singleMapData, singleMapDataIdx) => {
                    let param1 = this.props.logParamData[seriesIndex][singleMapDataIdx];
                    let param2 = this.props.logParamData[seriesIndex][singleMapDataIdx + 1];
                    let percentage1 = (param1 - paramMin) / (paramMax - paramMin) * 100;
                    let percentage2 = (param2 - paramMin) / (paramMax - paramMin) * 100;

                    let coord1 = this.props.logMapData[singleMapDataIdx];
                    let coord2 = this.props.logMapData[singleMapDataIdx + 1];
                    let coordArray = [];
                    let arrayOfColor = [];
                    let color1 = this.getGradientColor({r:0, g:255, b:0}, {r:255, g:0, b:0}, percentage1);
                    let color2 = this.getGradientColor({r:0, g:255, b:0}, {r:255, g:0, b:0}, percentage2);
                    for(let i = 0; i <= 100; i+=20) {
                        coordArray.push(this.getMoreCoords(coord1, coord2, i));
                        arrayOfColor.push(this.getGradientColor(color1, color2, i))
                    }

                    return coordArray.slice(0, coordArray.length - 1).map((singleCoord, singleCoordIdx) => {
                        return (
                            <Polyline
                                path={[
                                    coordArray[singleCoordIdx],
                                    coordArray[singleCoordIdx + 1]
                                ]}
                                options={{
                                    strokeColor: this.getGradientColor(arrayOfColor[singleCoordIdx],arrayOfColor[singleCoordIdx + 1], 50).cssColor,
                                    strokeOpacity: 1,
                                    strokeWeight: 3,
                                }}
                            />
                        )
                    })
                })}

                <Marker
                    position={this.props.logMapData[this.props.logMapData.length-1]}
                    title={"End of Log"}
                    icon={"http://maps.google.com/mapfiles/kml/pal5/icon13.png"}
                />
            </GoogleMap>
        );
    }
}

export default withScriptjs(withGoogleMap(MapViewer));