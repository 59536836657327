import React, { Component } from 'react';
import Config from 'Config'

import styles from './headButtons.module.scss';

class HeadButtons extends Component {
    render() {
        return (
            <div className={`${styles.headButtons} bgTertiary`}>
                <a className={styles.back} href={`${Config.dashboard}/logs`} data-hint={this.props.hints.back}>
                    <img src={require(`images/icons/back-dark.svg`)} alt="Back" />
                </a>

                <a className={styles.logo} href={Config.dashboard} data-hint={this.props.hints.logo}>
                    <img src={require(`images/speedweaver.svg`)} alt="Speedweaver" title={Config.version}/>
                </a>

                {this.props.fullScreen ?
                    <img
                        alt="Disable Full Screen"
                        className={styles.toggleFulLScreen}
                        onClick={this.props.fullScreenToggler}
                        src={require(`images/icons/disableFullScreen-dark.svg`)}
                        data-hint={this.props.hints.disableFullScreen}
                    />
                    :
                    <img
                        alt="Enable Full Screen"
                        className={styles.toggleFulLScreen}
                        onClick={this.props.fullScreenToggler}
                        src={require(`images/icons/enableFullScreen-dark.svg`)}
                        data-hint={this.props.hints.enableFullScreen}
                    />
                }
            </div>
        )
    }
}

export default HeadButtons;
