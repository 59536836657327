import React, { Component } from 'react';
import { Context } from "context/context";

import ReactDropdown from "react-dropdown";
import slugify from "slugify";
import sanitize from "sanitize-filename";
import fileDownload from "js-file-download";

import styles from './buttonToolbar.module.scss';

import ToggleButton from "react-toggle-button";
import * as Requests from 'data/requests';
import segment from "segment";


class ButtonToolbar extends Component {
    static contextType = Context;

    downloadLog = () => {
        let fileName = slugify(this.props.title);
        fileName = sanitize(fileName);
        if(fileName === "") fileName = "Log-Csv";

        return new Promise((resolve, reject) => {
            Requests.downloadLog(this.props.logId).then((file) => {
                fileDownload(file, `${fileName}.csv`);
                segment.track('log_downloaded', {
                    log: this.props.logId
                });
                resolve();
            }).catch((error) => {
                console.log(error);
                reject()
            })
        })
    };

    render() {
        return (
            <div className={`${styles.buttonToolbar} bgPrimary highlightTertiary`}>
                <div className={styles.seriesOptionsContainer}>
                    <div>
                        <div className={styles.downloadLog} data-hint={this.props.hints.title} onClick={this.downloadLog}>
                            <p className={styles.title}>{this.props.title}</p>
                            <img src={require(`images/icons/${this.context.lightTheme ? "download-grey" : "download"}.svg`)} alt="" />
                        </div>
                        <button className={`btn btnTertiary ${styles.btn} ${styles.btnTertiary}`} onClick={this.props.setFullMinMax} data-hint={this.props.hints.entireLog}>Show entire log</button>
                        <button className={`btn btnTertiary ${styles.btn} ${styles.btnTertiary}`} onClick={this.props.resetToStart} data-hint={this.props.hints.allSeriesOneChannel}>Show all series on one channel</button>
                        <button className={`btn btnTertiary ${styles.btn} ${styles.btnTertiary}`} onClick={this.props.resetMinMax} data-hint={this.props.hints.resetMinMax}>Reset min / max view to first 30s</button>
                    </div>

                    <div data-hint={this.props.hints.panZoomToggle}>
                        <p>Chart Drag Type:</p>

                        {/*@todo wanted to make this toggle into a dropdown but dropdown doesnt support bools*/}
                        {/*<ReactDropdown*/}
                        {/*    className=""*/}
                        {/* Couldnt get it to send value up through function*/}
                        {/*    onChange={this.props.updateDragOrZoom}*/}
                        {/*    options={[*/}
                        {/*        {*/}
                        {/*            label: 'Pan',*/}
                        {/*            value: 'false'*/}
                        {/*        }, {*/}
                        {/*            label: 'Zoom',*/}
                        {/*            value: 'true'*/}
                        {/*        }*/}
                        {/*    ]}*/}
                        {/*    value={ this.props.dragOrZoom.toString() }*/}
                        {/*/>*/}

                        <ToggleButton
                            activeLabel={'Zoom'}
                            activeLabelStyle={{ width:'55px' }}
                            containerStyle={{display:'inline-block',width:'65px'}}
                            inactiveLabel={'Pan'}
                            inactiveLabelStyle={{ width:'65px' }}
                            thumbAnimateRange={[1, 45]}
                            trackStyle={{width:'63px'}}
                            onToggle={(value) => {
                                this.props.updateDragOrZoom(!value)
                            }}
                            value={ this.props.dragOrZoom }
                            colors={{
                                inactive: {
                                    base: this.context.lightTheme ? 'rgb(116, 116, 116)' : 'rgb(65, 66, 68)',
                                }
                            }}
                        />
                    </div>
                </div>

                <div className={styles.navigatorOptions} data-hint={this.props.hints.navDropdown}>
                    <p data-hint={this.props.hints.navMapText}>Navigator {this.props.showMapButton && 'and Map '}Series: </p>
                    <span className={styles.colourCircle} style={{backgroundColor: `${this.props.colors[this.props.navigatorSeriesToggles.indexOf(true)]}`}} data-hint={this.props.hints.navMapText}/>
                    <ReactDropdown
                        className={`${styles.seriesOptions} navMapDropdown bgPrimary highlightPrimary`}
                        menuClassName={"bgSecondary highlightSecondary"}
                        onChange={this.props.toggleNavigatorSeries}
                        options={
                            this.props.navigatorSeriesToggles.map((seriesToggleValue, seriesToggleIdx) => {
                                return {
                                    label: this.props.headers[seriesToggleIdx].label,
                                    value: seriesToggleIdx
                                }
                            })
                        }
                        value={this.props.selectLabel}
                    />

                    {this.props.showMapButton &&
                    <div className={styles.mapBtn} data-hint={this.props.hints.showMap}>
                        <button className={`btn btnTertiary ${styles.btn} ${styles.btnTertiary}`}
                                onClick={this.props.toggleMap}> {this.props.isMapEnabled ? "Hide" : "Show"} Map
                        </button>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default ButtonToolbar;
